@import '../variables';

.simple-modal {
  color: #323232;
  display: none;
  position: fixed;
  opacity: 0;
  left: 0;
  right: 0;
  bottom: 15px;
  visibility: hidden;
  background-color: #fff;
  padding: 50px 20px 20px 20px;
  border-radius: 5px;
  margin: 0 15px;
  transition: opacity .3s ease-in-out, transform .2s ease-in-out, visibility .4s;

  @media (min-width: $mobile-breakpoint) {
    margin: 0 auto;
    width: 500px;
  }

  @media (min-width: $laptop-breakpoint) {
    top: 50%;
    transform: translateY(-50%);
    bottom: initial;
    width: 800px;
    padding: 50px 80px 80px 80px;
  }

  &--open {
    visibility: visible;
    z-index: 1009;
    opacity: 1;
  }

  &__heading {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: #222222;
    text-transform: uppercase;
    font-size: 20px;
    margin-bottom: 60px;

    i {
      cursor: pointer;
      font-weight: bold;
    }
  }

  &__content {
    font-size: 14px;
    max-height: 70vh;
    overflow: auto;
    margin-top: 20px;
  }

  &__footer {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
  }

  &__overlay {
    background-color: rgba(51, 51, 51, 0.8);
    backdrop-filter: blur(2px);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }

  &__close {
    cursor: pointer;
    $w: 32px;
    background-color: #000;
    border-radius: 50%;
    color: #fff;
    position: absolute;
    right: -$w / 5;
    top: -$w / 2;
    font-size: .7em;
    width: $w;
    height: $w;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: $mobile-breakpoint) {
      top: -10px;
      right: -10px;
    }
  }
}
