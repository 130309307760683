.background-container {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: -1;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0,0,0,.3);
    width: 100%;
    height: 100%;
  }

  .background {
    height: 100%;
    width: 100%;
    object-position: center;
    object-fit: cover;
  }
}
