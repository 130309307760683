@import "../variables";

.distance-modal {
  max-width: 600px;
  padding: 50px;

  .simple-modal {
    &__content {
      margin-top: 0;
      margin-bottom: 30px;
      line-height: 20px;
      text-align: center;
    }

    &__close {
      position: static;
      background-color: #bbb !important;
      color: #ffffff !important;
    }
  }

  .btn {
    height: inherit;
    width: 205px;
    text-align: center;
    padding: 15px 0;
    margin: 0 !important;
  }
}
