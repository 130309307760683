@import '../variables';

.takeaway-modal {
  &__slider {
    position: relative;
    min-width: 196px;
    min-height: 32px;
    max-width: calc(100% - 32px * 2);
    margin: 0 auto 25px auto;

    @media (min-width: $laptop-breakpoint) {
      width: 100%;
      height: 320px;
      overflow: auto;
      margin-left: 0;
      margin-right: 0;
      max-width: initial;
    }
  }

  &__slider-item {
    min-height: 32px;
    align-items: center !important;
    justify-content: center;
    text-align: center;
    color: #323232;
    font-weight: bold;

    @media (min-width: $laptop-breakpoint) {
      display: flex;
      justify-content: space-between;
      text-align: left;

      padding: 15px 0;

      &:first-child {
        border-top: 1px solid #dddddd;
      }

      & > a {
        margin: 0 20px;
      }

      border-bottom: 1px solid #dddddd;
    }

    &__info {
      display: none;

      @media (min-width: $laptop-breakpoint) {
        display: block;
        text-align: left;

        p {
          font-weight: normal;
          margin: 0;

          &:first-child {
            margin-top: 12px;
          }

          &:not(:last-child) {
            margin-bottom: 12px;
          }
        }
      }
    }

    &__link.btn {
      font-size: 14px;
      text-decoration: none;
      display: none;
      padding: 12px 28px;

      @media (min-width: $laptop-breakpoint) {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 90px;
      }
    }
  }

  &__slider-arrow {
    $w: 32px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;

    width: $w;
    height: $w;
    background-color: #222222;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &[aria-disabled='true'] {
      background-color: #dddddd;
      color: #222222;
      cursor: default;
    }

    &--left {
      left: -$w;
      border-bottom-left-radius: 8px;
      border-top-left-radius: 8px;
    }

    &--right {
      right: -$w;
      border-bottom-right-radius: 8px;
      border-top-right-radius: 8px;
    }
  }

  &__order-btn.btn {
    text-align: center;
    text-decoration: none;
    font-size: 14px;

    @media (min-width: $laptop-breakpoint) {
      display: none;
    }
  }

  &__info {
    text-align: center;
    font-size: 14px;
    margin-bottom: 40px;

    @media (min-width: $laptop-breakpoint) {
      display: none;
    }

    p {
      margin: 0;

      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }
}
