@import '../variables';

.title {
  font-size: 32px;
  margin-bottom: 31px;
  font-weight: 600;
  line-height: 50px;

  @media (max-width: $mobile-breakpoint) {
    font-size: 26px;
    margin-bottom: 20px;
  }
}

.slick-track {
  display: flex !important;
  align-items: flex-end;
}
.slick-track .slick-slide {
  display: flex;
  height: auto;
  align-items: flex-end;
}
