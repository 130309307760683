@import 'variables';

.header {
  background: #141a18;

  .alert-container {
    text-align: center;
    background: #FFF;
    color: #141a18;

    * {
      margin: 0;
      padding: 8px 20px;
      font-size: 14px;

      @media (max-width: $mobile-breakpoint) {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }

  .logo-container {
    padding: 10px 20%;

    .logo {
      max-height: 60px;
      max-width: 200px;
    }

    @media (max-width: $mobile-breakpoint) {
      padding: 10px 15px;
    }
  }
}
