@import 'modals/simple-modal';
@import 'modals/takeaway-modal';
@import 'modals/distance-modal';
@import 'components/title';
@import 'components/autocomplete';
@import 'components/button';
@import 'loading';
@import 'header';
@import 'background';
@import 'main';

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  color: #FFF;
}
