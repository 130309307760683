.autocomplete {
  $this: &;
  font-family: Quicksand, sans-serif;
  position: relative;
  max-width: $container-width;

  &--active {
    #{$this}__results {
      display: block;
    }
  }

  &__input {
    width: 100%;
    font-size: 16px;
    color: #777777;
    padding: 20px 15px;
    border: none;
    border-radius: 5px;
    outline: none;
    margin-bottom: 14px;
  }

  &__results {
    display: none;
    position: absolute;
    background: white;
    border-radius: 5px;
    width: 100%;
  }

  &__item {
    color: #999;
    padding: 13px 15px;
    font-size: 14px;
    border-radius: 5px;
    cursor: default;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &__main-match {
      color: #000;
    }

    &:hover {
      background-color: #fafafa;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #dddddd;
    }

    &--geo-location {
      padding-top: 17px;
      padding-bottom: 17px;
      font-size: 16px;
      display: flex;
      align-items: center;

      i {
        font-weight: 600;
        margin-right: 13px;
      }
    }

    &--error {

    }
  }

  &__attribution {
    position: absolute;
    height: 18px;
    bottom: -18px - 5px;
  }
}
