@import 'variables';

.main {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 20%;

  &__wrapper {
    width: 100%;
  }

  &__message {
    display: none;
    font-size: 16px;
    max-width: $container-width;

    &__delivery {
      margin: 10px 0 0 0;
    }

    a {
      color: #fff;
      text-decoration: underline;
    }
  }

  &__buttons {
    max-width: $container-width;
    margin-bottom: 14px;
    display: flex;
    justify-content: space-between;
  }

  &__autocomplete[data-multiple-services="false"] {

    .btn {
      margin-right: 0;
    }

    @media (min-width: $laptop-breakpoint) {
      display: flex;

      > * {
        flex-grow: 1;

        &.main__buttons {
          margin-left: 15px;
          max-width: calc(#{$container-width} / 2);
        }
      }
    }
  }

  @media (max-width: $mobile-breakpoint) {
    padding: 0 15px 120px 15px;
    align-items: flex-end;
  }
}
