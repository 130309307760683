.btn {
  padding: 18px 20px;
  border-radius: 5px;
  outline: none;
  border: none;
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  cursor: pointer;
  display: block;
  text-decoration: none;

  &:first-child {
    margin-right: 10px;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: #bbb !important;
    color: #ffffff !important;
  }

  &.hide {
    display: none;
  }
}
